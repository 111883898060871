@media screen and (max-width: 1300px) {
	.h-100per-modal-b {
    height: calc(88vh - (6rem + 0px));
}
}
@media screen and (max-width: 1024px) {
	.h-100per-modal-b{
    height:unset
  }
}

@media screen and (max-width: 820px) {
  .topic-abj {
      font-size: 32px;
  }
  .box-abjective{
    padding: 15px 15px;
  }
  .topic-news {
    font-size: 22px;
}
}

@media screen and (max-width: 490px) {
  body{
    font-size: 14px !important;
  }
    .wrapper{
        padding: 0px;
    }
    .logo-home{
      padding: 10px 15px 0;
    }
    .w-md-50{
      width: 100%;
    }
    .btn-apply-border{
      padding: 7px 40px;
    }
    .box-index{
        margin-top: 75px;
    }
    .acc-custom .accordion-body {
      padding: 10px 30px 10px 30px;
  }
    /* .banner-custom .alice-carousel__prev-btn{
        left: 5px;
    }
    .banner-custom .alice-carousel__next-btn{
        right: 5px;
    }
    .banner-custom .sorn-left,.banner-custom .sorn-right{
        height: 25px;
    }
    .banner-custom .alice-carousel__dots {
        bottom: 0px;
        right: 8px;
    }
    .banner-custom .alice-carousel__dots-item:not(.__custom) {
        width: 20px !important;
        height: 4px !important;
    } */
    .form-control{
        border-bottom: 1px solid #1C184A;
    }
    /* .btn-login{
        background-color: #1C184A;
        color: #fff !important;
        padding: 5px 30px !important;
        display: block;
        width: 100%;
    } */
    .box-num-time{
        position: relative;
        bottom: 0px;
        margin-top: 15px;
    }
    .bg-menu-m{
        top: 0;
    }
    .box-news{
        margin: 30px 0;
      }
      .box-section-youtube{
        margin-top: 0px;
      }
      .present-height{
        height: 220px;
      }
      .box-youtube{
        bottom: 100px;
      }
      .box-absolute-res{
        position:relative !important;
      }
      .box-footer {
        padding: 30px 0px 15px;
      }
      .click-page{
        padding:0;
      }
      .obj-slide .alice-carousel__stage-item{
        padding: 15px !important;
      }
      .box-p{
        box-shadow: 0 0 10px 0px #dbdfff;
      }
      .overlay {
        height: 35%;
        opacity: 1;
    }
    .overlay-text{
        bottom: 15px;
    }
    .logo-home a img {
        width: 120px;
    }

    .relative-res{
        position:  relative !important;
        top: unset !important;
        transform: unset !important;
    }
    .topic-news {
        font-size: 22px;
    }
    .box-abjective{
        margin-bottom: 60px;
        border-radius: 12px;
    }
    .btn-contact{
      font-size: 10px;
    }
    .font-12 {
      font-size: 10px !important;
  }
    .font-14 {
      font-size: 12px !important;
  }
    .font-16 {
      font-size: 14px !important;
  }
    .font-18 {
      font-size: 14px !important;
  }
    .font-20 {
      font-size: 16px !important;
  }
    .font-22 {
      font-size: 18px !important;
  }
}
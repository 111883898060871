@import url("../font/stylesheet.css");
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #ffffff;
  /*    border: 0px none #ffffff;*/
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background:#ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #ffffff
}

::-webkit-scrollbar-track {
  background: #B59E8B;
  /*    border: 0px none #ffffff;*/
  border-radius: 50px;
}
.bg-menu-top{
  background: #fff
  /* border-bottom: 5px solid red; */
  /* border: solid 5px transparent; */
  /* background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(0,30,36,0) 100%); */
}
.line-gra{
   background: #212968;
/* background: linear-gradient(270deg, rgba(247,175,207,1) 18%, rgba(255,255,255,1) 100%); */
  bottom: -5px;
  height: 5px;
  width: 100%;
  left: 0;
}
.bg-menu-top-2{
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
/* .box-index {
  margin-top: -140px;
} */
.logo-home {
  /* position: absolute; */
  left: 30px;
  top: 30px;
  text-align: left;
}
.logo-home a img{
  width: 160px;
}
.box-text-home {
  position: absolute;
  left: 150px;
  /* padding:90px; */
  bottom: 90px;
  /* transform: translate(0, -50%); */
  /* width: 50%; */
}
.border-blue{
  border-bottom: 5px solid #1C184A;
}
.absolute-center{
  top: 50%;
  transform: translate(0, -50%);
}
.absolute-bottom{
  bottom: 0;
}
.absolute-navigation{
  top: 15px;
  transform: translate(0, 0%);
}
.w-md-50{
  width: 50%;
}
.box-absolute-center{
  top: 50%;
  left: 50%;;
  transform: translate(-50%, -50%);
}
.absolute-bottom-career{
  bottom: 60px;
  left: 50%;;
  transform: translate(-50%, 0%);
}

hr.hr-green{
  background-color:#11819F;
  opacity: 0.9;
}
.box-num-time{
  position:absolute;
  bottom: -80px;
}
.box-news{
  margin-top: 120px;
  margin-bottom: 120px;
}
.box-section-youtube{
  margin-top: -120px;
}

/* .font-mn{
  font-family: "Khmer MN";
}
.font-mt{
  font-family: "Bodoni MT";
} */
.num-dis{
  /* font-family: "Khmer MN"; */
  font-size: 200px;
  line-height: 0 !important;
  margin-bottom: -40px;
}
.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active{
  background-color: #2F2F2F !important;
}
.alice-carousel__dots-item:not(.__custom) {
  background-color: #C1C1C1 !important;
  width: 30px !important;
  height: 4px !important;
  border-radius: unset !important;
}
.banner-custom .alice-carousel__dots{
  position: absolute;
  bottom: 20px;
  right: 150px;
  
}
.banner-custom .alice-carousel__dots-item:not(.__custom) {
  background-color: #8D87A6 !important;
}
.banner-custom .alice-carousel__dots-item:not(.__custom):hover,.banner-custom .alice-carousel__dots-item:not(.__custom).__active{
  background-color: #212968 !important;
}
.size-per{
  font-size: 100px;
}
ul.menu-top {
  display: table;
}
ul.menu-top li {
  list-style: none;
  display: table-cell;
  padding: 0px 10px;
  text-align: right;
}
ul.menu-top li a {
  padding: 0 15px;
  color: #000;
  vertical-align: bottom;  
  margin-bottom: 10px;
  /* font-family: "Bodoni MT"; */
}

ul.menu-top2 {
  display: table;
}
ul.menu-top2 li {
  list-style: none;
  display: table-cell;
  padding: 15px 0;
}
ul.menu-top2 li a {
  padding: 0 15px;
  color: #000;
  /* font-family: "Bodoni MT"; */
}
/* ul.menu-top li a:hover,ul.menu-top li a.active {
  text-decoration: underline;
} */
.dp-menu button{
  background: unset;
  box-shadow: unset;
  border: unset;
  margin-bottom: 10px;
  padding-top: 0;
  font-weight: 300;
  vertical-align: bottom;
  padding-bottom: 0;
  font-weight: 500;
  color: #000;
}
.dp-menu button:hover,.dp-menu button:focus:not(:focus-visible),.dp-menu .btn:active,.btn-check:active+.btn:focus, .btn-check:checked+.btn:focus, .btn.active:focus, .btn.show:focus, .btn:active:focus{
  background: unset;
  border: unset;
  box-shadow: unset;
  color: #000;
}
.dp-menu .dropdown-menu{
  padding: 0;
  border-radius: 0;
}
.dp-menu .dropdown-item{
  color: #000;
    font-size: 14px;
    transition: 0.7s;
    margin-bottom: 0;
    padding: 5px 15px;
    text-align: center;
}
.dp-menu .dropdown-item:hover{
  color: #000;
  background: #E3E3E3;
}
.dp-menu.show button::after{
transform: rotate(180deg);
}
.menu-top-right{
  /* position: absolute;
  top: 0;
  right: 12px; */
  margin-bottom: 15px;
}
.icon-social{
  width: 30px;
}
.un,.un2 {
  display: inline-block;
}

.un::after {
  /* content: '';
  width: 0px;
  height: 1px;
  display: block;
  background: #fff; */
  transition: 300ms;
  margin-top: -4px;
}
.modal-header{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  border-bottom: unset;
}
.modal-content{
  background: unset;
}
.h-100-per-modal{
  height: calc(100vh - 6rem - 65px);
}
.un2::after {
  content: '';
  width: 0px;
  height: 1px;
  display: block;
  background: #000;
  transition: 300ms;
  margin-top: -4px;
}
.btn-blue{
  background-color: #1C184A;
  text-decoration: none;
  color: #fff;
  padding: 5px 60px;
  transition: all .3s ease;
  border: none;
}
.btn-blue:hover{
  opacity: .7;
  color: #fff;
}
.btn-default{
  color: #000;
  background: #FFFFFF;
  text-decoration: none;
  transition: all .3s ease;
}
.btn-default:hover{
  color: #919191;
  opacity: .7;
  background: #FFFFFF;
}
.modal-body{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.modal-footer{
  border-top:unset
}
.a-here:hover{
color:#E32D1B;
}
.a-default{
  color: #919191;
  text-decoration: none;
  transition: all .3s ease;
}
.a-white-default{
  color: #fff;
  text-decoration: none;
  transition: all .3s ease;
}
.a-default:hover{
  color: #000;
  /* text-decoration: underline; */
}
.a-white-default:hover{
  opacity: .7;
  color: #fff;
}

.box-detail p{
  margin: 0;
}
.un:hover::after,.un.active::after,.un2:hover::after,.un2.active::after {
  width: 100%;
}
.input-custom{
  background-color: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
  /* font-style: italic; */
  color: #000;
}
.border-all{
  border:1px solid #CCCCCC
}
.box-for{
  background-color: #fff;
  border-radius: 5px;
  color: #212968;
  text-decoration: none;
  transition: all .3s ease;
}
.box-for:hover{
background-color: #212968;
color: #fff;
box-shadow: 0 5px 15px -2px #212968;
}
.box-for:hover img{
  
  filter: brightness(100);
}
.btn-apply{
  background-color: #E32D1B;
  text-decoration: none;
  color: #fff;
  padding: 10px 60px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  transition: all .3s ease;
}
.btn-apply:hover{
  box-shadow: 0 5px 15px -2px #E32D1B;
  color: #fff;
}
.btn-apply-border{
  border:1px solid #E32D1B;
  text-decoration: none;
  color: #E32D1B;
  padding: 7px 60px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  transition: all .3s ease;
}
.btn-learn-border{
  border:1px solid #212968;
  text-decoration: none;
  color: #212968;
  padding: 7px 60px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  transition: all .3s ease;
}
.btn-apply-border:hover{
  background-color: #E32D1B;
  box-shadow: 0 5px 15px -2px #E32D1B;
  color: #fff;
}
.btn-border-blue{
  text-decoration: none;
  border:1px solid #212968;
  border-radius: 3px;
  color: #212968;
  padding: 7px 45px;
  font-weight: 600;
  display: inline-block;
  transition: all .3s ease;
  }
  .btn-border-blue:hover{
    background:#212968;
    box-shadow: 0 5px 15px -2px #212968;
    color: #fff;
  }

  .btn-box{
    text-decoration: none;
    color: #000;
    box-shadow: 0 5px 15px -10px #212968;
    border-radius: 2px;
    transition: all .3s ease;
    overflow: hidden;
  }
  .btn-box:hover{
    color: #fff;
    box-shadow: 0 5px 15px -5px #212968;
  }
  .btn-contact{
    display: block;
    background-color: #F0F0F0;
    text-decoration: none;
    color: #000;
    font-size: 13px;
    padding: 5px 15px;
    transition: all .3s ease;
  }
  .btn-contact:hover{
    color: #000;
    opacity: .7;
  }
  .box-hover-blue{
    background-color: #fff;
  }
  .btn-box:hover .box-hover-blue{
    background-color: #212968;
  }
  .btn-box:hover .btn-learn-border{
    background-color: #fff;
  }
.input-custom-mail{
  background-color: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
  /* font-style: italic; */
  color: #fff;
}

.register-custom{
  background-color: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
  font-style: italic;

  /* color: ; */
}
.form-control:focus,.form-select:focus{  
  box-shadow: unset !important;
  border-color: unset;
}
.input-custom:focus{
  /* background: rgba(255,255,255,0); */
  color: #000;
}
.input-custom-mail:focus{
  background: rgba(255,255,255,0);
  color: #fff;
}
/* .h-100per-modal{
  height: calc(90vh - (6rem + 10px));
  overflow-y: auto;
}    */
.h-100per-modal-b {
  height: calc(80vh - (6rem + 10px));
  overflow-y: auto;
}  
.box-footer {
  padding: 30px 0px 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/* new */
.hbtn {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  padding: 10px 90px;
  margin: 0px 3px 6px;
  text-align: center;
  /* border: 2px solid rgb(255, 255, 255); */
  text-decoration: none;
  color: #070707;
  white-space: nowrap;
  z-index: 0;
  font-weight: 600;
  background: #E8E8E8;
} 
.hbtn i {
  padding-right: 8px;
} 
.hb-fill-right::before {
  position: absolute;
  content: "";
  background: #60A6DB;
  transition-duration: 0.3s;
  z-index: -1;
  inset: 0px auto auto 0px;
  width: 15px;
  height: 100%;
  opacity: 1;
} 
.hb-fill-right:hover::before {
  width: 100%;
  height: 100%;
  opacity: 1;
} 
.hb-fill-right:hover {
  color: #fff;
  background: rgb(255, 255, 255);
  transition: color 0.3s ease 0s, background 0s ease 0.3s;
} 

.alice-carousel__stage-item img{
  padding: 5px !important;
}
.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 3px !important;
}
.alice-carousel__prev-btn, .alice-carousel__next-btn{
  position: absolute;
  width: 0% !important;
 bottom: 30px;
}
/* .alice-carousel__prev-btn{
  left: -40px;
}
.alice-carousel__next-btn{
  right: -40px;
} */

.banner-custom .alice-carousel__stage-item img{
  padding: 0px !important;
}
.banner-custom .alice-carousel__prev-btn{
  right:80px;
  background: rgba(126,126,126,0.4);
  width: 35px !important;
  height: 35px;
  border-radius: 5px;
  transition: 0.7s;
  cursor: pointer;
}
.banner-custom .alice-carousel__next-btn{
  right: 35px;
  background: rgba(126,126,126,0.4);
  width: 35px !important;
  height: 35px;
  border-radius: 5px;
  transition: 0.7s;
  cursor: pointer;
}
.box-length{
  background-color: rgba(0,0,0,0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.banner-custom-2 .alice-carousel__prev-btn{
  right: 65px;
}
.banner-custom-2 .alice-carousel__next-btn{
  right: 20px;
}
.banner-custom-2 .alice-carousel__prev-btn,.banner-custom-2 .alice-carousel__next-btn{
  bottom: 15px;
}

.banner-custom .alice-carousel__next-btn:hover,.banner-custom .alice-carousel__prev-btn:hover{
  background: rgba(126,126,126,0.8);
}
.alice-carousel__prev-btn{
  text-align: left !important;
}
.alice-carousel__next-btn{
  text-align: right !important;
}
.sorn-left,.sorn-right{
  cursor: pointer;
  opacity: 1;
  transition: 0.7s;
}
.banner-custom .sorn-left,.banner-custom .sorn-right{
  cursor: pointer;
  opacity: 1;
  transition: 0.7s;
  filter: brightness(0) invert(1);
  width: 10px;
}
.banner-custom .sorn-left:hover,.banner-custom .sorn-right:hover{
  opacity: 1;
}
.banner-custom .sorn-left{
  transform: rotate(180deg);
  width: 10px;
}
.sorn-left:hover,.sorn-right:hover{
  opacity: 1;
}
.sorn-left{
  transform: rotate(180deg);
}


.acc-custom .accordion-button:focus{
  border-color:unset;
  box-shadow:unset;
}
/* .acc-custom .accordion-header{
  color: #fff;
} */
.acc-custom .accordion-item{
  background-color: unset;
    border: unset;
    padding: 10px 0;
    border-bottom: 1px solid #CDCDCD !important;
    border-radius: 0;
}
.acc-custom .accordion-button{
  background-color: unset;
  padding: 0;
  /* color: #fff; */
}
.acc-custom .accordion-button img{
transform: rotate(90deg);
transition: 0.3s;
}
.acc-custom .accordion-button:not(.collapsed) img{
transform: rotate(270deg);
}
.acc-custom .accordion-body{
  padding: 10px 150px 10px 45px;
}
.acc-custom .accordion-button:not(.collapsed)::after{
  background: unset;

}
.acc-custom .accordion-button::after{
  background: unset
}

.btn-check:focus+.btn, .btn:focus{
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
}
.number-time{
  font-size: 42px;
}
.text-time{
  font-size: 16px;
}
.mum-time{
  background: rgb(246, 174, 206);
    width: 60px;
    height: 30px;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    right: -19px;
    top: -4px;
    transform: rotate(45deg);
}
.topic-news{
  font-size: 32px;

}
.see-more{
  text-decoration: none;
  transition: 0.7s;
}
.see-more:hover{
  color: #60A6DB;
  opacity: 0.7;
}
.border-loop{
  border-bottom: 1px solid #E4E4E4;
}
.border-loop:last-child{
  border-bottom: 0px solid red;
}
.img-seemore{
  width: 25px;
}
/* .font-bb{
  font-family: 'Bebas';
} */
.day-sum{
  font-size: 62px;
  font-weight: 300;
  line-height: 1 !important;
}
.month-sum{
  font-size: 31px;
  margin-top: -7px;
  display: block;
}
.img-hover{
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0%;
  width: 100%;
  opacity: 0;
  transition: all .3s ease;
  background-color: rgba(0,0,0,0.7);
}
.overlay-text {
  width: 100%;
  color: white;
  font-size: 22px;
  padding: 0 30px;
  position: absolute;
  bottom: 80px;;
  left: 50%;
  -webkit-transform: translate(-50%, -0%);
  -ms-transform: translate(-50%, -0%);
  transform: translate(-50%, -0%);
  text-align: center;
  /* transition: all .3s ease-out; */
}
.img-hover:hover .overlay{
  opacity: 1;
  height: 100%;
  transition: all .3s ease;
}

.box-youtube{
  position: absolute;
  bottom: 30px;
  width: 100%;
}
.cursor-pointer{
  cursor: pointer;
}
.box-p{
  background: #FFFFFF;
  border-radius: 5px;
  padding:20px;
  cursor: pointer;
  transition: all .3s ease;
}
.box-p:hover{
  background: #212968;
  color: #fff;
  box-shadow: 0 0 10px 0px #dbdfff;
}
.box-p:hover img{
  filter: brightness(0) invert(1);
}
.box-p:hover .btn-more{
  background: #fff;
}
.present-height{
  height: 300px;
  overflow-y: auto;
  padding-right: 12px;
}
.btn-more{
  color: #212968;
  text-decoration: none;
  background-color: #DBDFFF;
  padding: 7px 0px;
  transition: all .3s ease;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
}
.btn-more:hover{
/* opacity: 0.7; */
color: #212968;
background: #fff;
}
.click-page{
  /* background-color: #FFFFFF; */
  text-decoration: none;
  padding: 0px 15px;
  width: 100%;
  color: #8C90B1;
  display: block;
  transition: all .3s ease;
}
.click-page:hover{
  color: #fff
}
.box-an:hover .text-over{
  opacity: 1;
}
.text-over{
  background: rgba(0,0,0,0.5);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%,-50%);
  opacity: 0;
  transition: all .3s ease;
}
.btn-center-absolute{
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.box-abjective{
  background: #212968;
    color: #fff;
    padding: 40px 15px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
}
.bg-img-center{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.bg-img-left{
  background-size: contain;
  background-position: left center;
}
.bg-top-center{
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}
.topic-abj{
  font-size: 42px;
  font-weight: 700;
}
.sh-pass{
  position: absolute;
  right: 0;
  bottom: 3px;
}
.hide-password{
  height: 22px;
    border: 1px solid #969696;
    transform: rotate(-45deg);
    position: absolute;
    top: 1px;
    left: 9px;
}
body .main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 200ms ease;
  background: var(--background-modal-color);
  /* height: 220px;
  min-width: 420px;
  max-width: 750px; */
  flex-grow: 1;
  border-radius: 5px;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14); */
}
body .main-container .steps-container {
  padding: 40px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .main-container .steps-container .step {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
  flex-grow: 0;
  height: 30px;
  width: 30px;
  border: 4px solid var(--color-timeline-default);
  border-radius: 50%;
  background: #EDEDED;
}
body .main-container .steps-container .step .preloader, body .main-container .steps-container .step svg {
  display: none;
}
body .main-container .steps-container .step.completed {
  width: 30px;
  height: 30px;
  background: #212968;
  border: none;
}
body .main-container .steps-container .step.completed svg {
  transition: all 200ms ease;
  display: block;
  height: 10px;
  width: 10px;
  filter: brightness(0) invert(1);
}
body .main-container .steps-container .step.in-progress {
  width: 30px;
  height: 30px;
  background: #EDEDED;
  border: 3px solid #212968;
}
body .main-container .steps-container .step.in-progress .preloader {
  display: block;
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
  border-left-color: transparent;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
body .main-container .steps-container .step .label {
  position: absolute;
  top: 30px;
  filter: none;
  z-index: 2000;
  color: #9A9A9A;
  transition: all 200ms ease;
  font-weight: 500;
  font-size: 12px;
  width: 40px;
  text-align: center;
  margin-top: 15px;
}
body .main-container .steps-container .step .label.completed {
  color: #212968;
}
body .main-container .steps-container .step .label.loading {
  color: #9A9A9A;
}
body .main-container .steps-container .step .icon {
  font-size: 40px;
  position: absolute;
  top: -60px;
  color: var(--color-icon-default);
  transition: color 200ms ease;
}
body .main-container .steps-container .step .icon.completed {
  color: var(--color-icon-completed);
}
body .main-container .steps-container .step .icon.in-progress {
  color: var(--color-in-progress);
}
body .main-container .steps-container .line {
  transition: all 200ms ease;
  height: 3px;
  flex-grow: 1;
  max-width: 120px;
  background: #212968;
}
body .main-container .steps-container .line.completed {
  background: #212968;
}
body .main-container .steps-container .line.next-step-uncomplete {
  background: #212968;
}
body .main-container .steps-container .line.next-step-in-progress {
  background: #212968;
}
body .main-container .steps-container .line.prev-step-in-progress {
  background: #EDEDED;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dp-name .dropdown-toggle{
  margin-bottom: 0;
}
.bg-loading{
    position:absolute;
    width: 100%;
    background: rgba(255,255,255,0.9);
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
}
.icon-loading{
  width: 31px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%,-50%);
}
.form-control.is-invalid, .was-validated .form-control:invalid{
  border-color: #dc3545 !important;
  background-image: unset !important
  /* box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%); */
}
.form-control.is-valid, .was-validated .form-control:valid{
  background-image: unset !important
}
.form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-position: right 0.75rem center,center right 2.25rem;
  background-size: 16px 12px,calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-position: right 0.75rem center,center right 2.25rem;
  background-size: 16px 12px,calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.gallery-full .alice-carousel__prev-btn{
  top: unset;
  bottom: 0;
  left: 15px;
  /* filter: brightness(0) invert(1); */
}
.gallery-full .alice-carousel__next-btn{
  top: unset;
  bottom: 0;
  left: 40px;
  /* filter: brightness(0) invert(1); */
}
.gallery-full .alice-carousel__stage-item img {
  padding: 0px !important; 
}
.box-unit{
  border-left: 5px solid #F0F0F0;
  transition: all .3s ease;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.box-unit:hover{
  border-left: 5px solid #212968;
  box-shadow: 0px 1px 6px 0px #cdcdcd;
  color: #000;
}
.text-unit,.text-unit:hover{
  text-decoration: none;
  color: #000;
}
.box-cat{
  border-bottom: 1px solid #CDCDCD;
  padding: 15px 10px;
  transition: all .3s ease;
  color: #000;
  text-decoration: none;
}
.box-cat:hover,.box-cat.active{
/* text-decoration: underline; */
background-color: #DBDFFD;
color: #000;

}
.box-cat:last-child{
  border-bottom: 0px solid #CDCDCD;
}
/* .table-condensed thead tr:nth-child(2),
.table-condensed tbody {
  display: none
} */
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
  padding: 10px 2px;
}
.border-left{
  border-left: 5px solid #F0F0F0;
  transition: all .3s ease;
  cursor: pointer;
}
.border-left:hover{
  border-left: 5px solid #212968;
  transition: all .3s ease;
}
.box-calendar{
  /* text-decoration: underline; */
  border-bottom: 1px solid #CDCDCD;
  }
  .box-calendar:last-child{
    border-bottom: 0px solid #CDCDCD;
  }
.custom-tag .rti--container{
  border: unset;
  --rti-main:unset;
  padding: 0;
  margin-right: 35px;
}
.custom-tag .rti--container .rti--input{
  background-color: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
  /* font-style: italic; */
  color: #000;
  display: block;
  width: 100%;
  background-clip: padding-box;
  border-bottom: 1px solid #ced4da;
  appearance: none;
  font-weight: 400;
  line-height: 2;
  font-size: 14px;
  margin-top: 10px;
}
.custom-tag .rti--tag{
  font-size: 12px;
  border-radius:unset;
  font-weight: 500;
  padding: 5px 0px 5px 8px;
  background-color: #F0F0F0;
}
.box-unit-ep{
  background-color: #fff;
  border-top:5px solid #212968;
  box-shadow: 0px 1px 6px 0px #cdcdcd;
}
.box-ep{
  background-color: #F0F0F0;
  display: block;
  color: #000;
  text-decoration: none;
  transition: all .3s ease;
}
.box-ep:hover{
  display: block;
  color: #000;
  background-color: #DBDDFD;
  text-decoration: none;
}
p{
  margin: 0;
}
.btn-back{
  color: #000;
  text-decoration: none;
  background-color: #F0F0F0;
  transition: all .3s ease;
}
.btn-back:hover{
  color: #000;
  text-decoration: none;
  opacity: .7;
}
.react-calendar { 
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
 }
 .react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
 }
 .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
 background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
 background-color: #f0f0f0;
}
abbr[title] {
 text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
 color: #d10000;
} */
.react-calendar{
  border: unset !important;
  box-shadow: unset !important;
  margin: 0 auto;
}
.react-calendar__navigation{
  display: none !important;
}
.react-calendar__month-view__weekdays{
  border-bottom: 1px solid;
}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  color: unset !important;
  background-color: unset !important;
}
.react-calendar__tile--now {
  background: unset !important;
  color: unset !important;
  font-weight: unset;
}
.react-calendar__tile--active{
  color: unset !important;
  background: unset !important;
  font-weight: unset !important;
}
.react-calendar .highlight {
  color:#212968;
  background-image: url('../img/event.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 11px;
}
.react-calendar__month-view__days__day--weekend{
  color: unset !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #cfcece !important;
}
.h-box{
  height: 85px;
  overflow: hidden;
}
.h-box.active{
  height: auto;
}
.box-re{
  border-left: 5px solid #F0F0F0;
}
.readmore{
  text-decoration: underline;
  color:blue
}